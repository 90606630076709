// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useResponsive, ResponsiveType, useUrlFilters} from '@supermove/hooks';
import {UserModel, TeamModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Text from '@shared/design/components/Text';
import UserStatus, {UserStatusType} from '@shared/modules/User/enums/UserStatus';
import OfficeStaffSearchAndFilters from 'modules/Organization/Settings/Staff/components/OfficeStaffSearchAndFilters';
import OfficeStaffUserStatusTabs from 'modules/Organization/Settings/Staff/components/OfficeStaffUserStatusTabs';
import TeamsTable from 'modules/Organization/Settings/Staff/components/TeamsTable';

const Row = Styled.View`
  flex-direction: row;
`;

const Col = Styled.View`
`;

const PageContentContainer = Styled.View`
  flex: 1;
`;

const HeaderContainer = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: row;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

const FiltersContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

const TeamsTableContainer = Styled.View<{responsive: ResponsiveType}>`
  flex: 1;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

export const TEAMS = 'TEAMS' as const;
export type SelectedUserStatusType = UserStatusType | typeof TEAMS;

const UsersTable = ({
  selectedUserStatus,
  officeTeams,
  refetch,
  viewer,
  responsive,
}: {
  selectedUserStatus: SelectedUserStatusType;
  officeTeams: TeamModel[];
  refetch: () => void;
  viewer: UserModel;
  responsive: ResponsiveType;
}) => {
  switch (selectedUserStatus) {
    case 'TEAMS':
      return (
        <TeamsTableContainer responsive={responsive}>
          <TeamsTable teams={officeTeams} refetch={refetch} viewer={viewer} />
        </TeamsTableContainer>
      );
    default:
      return null;
  }
};

export type OfficeStaffContentV2FilterType = {
  searchQuery: string | undefined;
  roles: string[] | undefined;
  userStatus: SelectedUserStatusType;
};

const OrganizationSettingsStaffOfficeStaffContentV2 = () => {
  const responsive = useResponsive();
  const urlFilters = useUrlFilters<OfficeStaffContentV2FilterType>({
    getRoute: () => '/settings/staff/office-staff/office-members',
    filterKeys: ['searchQuery', 'roles', 'userStatus'],
    initialFilterValues: {userStatus: UserStatus.ACTIVE},
  });

  const {loading, data, refetch} = useQuery(OrganizationSettingsStaffOfficeStaffContentV2.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      searchQuery: urlFilters.get('searchQuery') || '', // we get no results for an empty search query without the empty string fallback value
      roles: urlFilters.get('roles'),
      activeUserStatus: UserStatus.ACTIVE,
      pendingUserStatus: UserStatus.PENDING,
      inactiveUserStatus: UserStatus.INACTIVE,
    },
  });

  return (
    <Loading loading={loading}>
      {() => {
        const {viewingOrganization: organization} = data.viewer;
        const {activeUsers, pendingUsers, inactiveUsers, officeTeams} = organization;
        const selectedUserStatus = urlFilters.get('userStatus');

        return (
          <ScrollView
            horizontal
            contentContainerStyle={{
              flex: 1,
            }}
          >
            <PageContentContainer>
              <HeaderContainer responsive={responsive}>
                <Col style={{flex: 1}}>
                  <Text.PageHeading>Office Staff</Text.PageHeading>
                  <Space height={responsive.desktop ? 16 : 8} />
                  <Text.Body style={{color: colors.gray.secondary}}>
                    View and manage office staff, and invite new office staff to your organization.
                  </Text.Body>
                </Col>
              </HeaderContainer>
              <Space height={responsive.desktop ? 24 : 16} />
              <FiltersContainer responsive={responsive}>
                <OfficeStaffSearchAndFilters
                  urlFilters={urlFilters}
                  viewerRole={data.viewer.role}
                />
                <Space height={responsive.desktop ? 24 : 16} />
                <OfficeStaffUserStatusTabs
                  selectedUserStatus={selectedUserStatus}
                  setSelectedUserStatus={(userStatus: SelectedUserStatusType) =>
                    urlFilters.handleUpdate({userStatus})
                  }
                  activeUsersCount={activeUsers.length}
                  pendingUsersCount={pendingUsers.length}
                  inactiveUsersCount={inactiveUsers.length}
                  teamsCount={officeTeams.length}
                />
              </FiltersContainer>
              <UsersTable
                selectedUserStatus={selectedUserStatus}
                officeTeams={officeTeams}
                refetch={refetch}
                viewer={data.viewer}
                responsive={responsive}
              />
            </PageContentContainer>
          </ScrollView>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
// TODO(Hammad) create OfficeAppUserCountByStatus API endpoint, refactor to use a single filteredOfficeAppUsers call
OrganizationSettingsStaffOfficeStaffContentV2.query = gql`
  ${TeamsTable.fragment}

  query OrganizationSettingsStaffOfficeStaffContentV2($searchQuery: String, $roles: [String], $activeUserStatus: String, $pendingUserStatus: String, $inactiveUserStatus: String) {
    ${gql.query}
    viewer {
      id
      role
      viewingOrganization {
        id
        activeUsers: filteredOfficeAppUsers(searchQuery: $searchQuery, roles: $roles, status: $activeUserStatus) {
          id
          fullName
          email
          phoneNumber
          role
          status
        }
        pendingUsers: filteredOfficeAppUsers(searchQuery: $searchQuery, roles: $roles, status: $pendingUserStatus) {
          id
          fullName
          email
          phoneNumber
          role
          status
        }
        inactiveUsers: filteredOfficeAppUsers(searchQuery: $searchQuery, roles: $roles, status: $inactiveUserStatus) {
          id
          fullName
          email
          phoneNumber
          role
          status
        }
        officeTeams {
          id
          members {
            id
            role
          }
          ...TeamsTable
        }
      }
      ...TeamsTable_Viewer
    }
  }
`;

export default OrganizationSettingsStaffOfficeStaffContentV2;
